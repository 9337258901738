.footer {
    @apply w-full p-4 flex justify-center items-center;
  }
  
  .footer--center {
    @apply justify-center;
  }
  
  .footer__logo {
    @apply h-12;
  }
  
  .footer__logo--loading {
    @apply opacity-50;
  }