
.help-pill {
    display: inline-block;
    position: relative;
    background-color: #008cff;
    color: white; 
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    cursor: pointer;
    margin-left: 8px;
    font-size: 0.875rem;
    z-index: 999;
  }
  
  .help-pill__tooltip {
    visibility: hidden;
    width: 350px;
    background-color: #008cff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -125px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .help-pill__tooltip img.help-pill__image {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
  
  .help-pill:hover .help-pill__tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  
  
  /*Loading*/
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
        transform: rotate(360deg);
    }
  }
  