
  .navbar {
    @apply w-full flex justify-between items-center h-[60px];
  }
  
  .navbar--main {
    @apply bg-deepBlue py-2 px-8;
  }
  
  .navbar__logo {
    @apply flex items-center;
  }
  
  .navbar__logo-image {
    @apply  h-[2.75rem] mr-[0.625rem];
  }
  
  .navbar__user {
    @apply text-white text-sm;
  }
  