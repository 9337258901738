@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply text-primaryTextColor bg-bodyBackground;
}

body {
  @apply text-primaryTextColor font-normal;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import './components/css/nav.css';
/* @import './components/css/body.css';*/
@import './components/css/body.css';
@import './components/css/footer.css';

@import './components/css/messages.css';
@import './components/css/utilities.css';


 /* Modal */

 .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(90, 90, 90, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

.modal-content {
  padding: 10px;
  position: relative;
  text-align: center;
  transform: scale(0.7);
  animation: zoomIn 0.3s forwards;
  border-radius: 8px;
}

/* Animaciones */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes zoomIn {
  from {
      transform: scale(0.7);
  }
  to {
      transform: scale(1);
  }
}

/* End Modal */

