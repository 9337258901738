

.container--form {
    @apply w-full h-[100px] p-8;
  }

.header--title {
  @apply bg-deepBlue rounded-full text-center;
}

.form--shadow {
  @apply shadow-md rounded-md p-4;
}

.label--primary {
  @apply text-deepBlue font-semibold text-[16px];
}

.text--label--primary {
  @apply text-sm text-deepBlue text-[14px];
}

.text--label--primary--bold {
  @apply text-sm text-deepBlue text-[14px] font-bold;
}




.header__text {
  @apply font-normal text-white p-1 px-4 text-[20px];
  font-variation-settings: 'wght' 400;
}





.content-separator {
  @apply w-full h-[1px] bg-lightGray mt-2 mb-2;
}

.grid-container--gapped {
  @apply grid grid-cols-1 gap-x-4;
}

.grid-container--responsive {
  @apply grid grid-cols-1 md:grid-cols-2 gap-x-8 mt-2;
}

.flex-container--column {
  @apply flex flex-col;
}

.flex-box--gap {
  @apply flex gap-x-4;
}

.panel--dashed {
  @apply border border-dashed rounded-lg p-2 mt-4;
}


.action-container--end {
  @apply mt-4 w-full flex justify-end;
}

.button--primary {
  @apply flex items-center bg-success hover:bg-successHover duration-500 py-1 px-2 rounded-md text-white;
}



.text--error {
  @apply text-red text-[12px] font-medium;
}


.form__input {
  @apply border border-[#9d9d9d] rounded-lg p-2 w-full text-base bg-white hover:border-skyBlue focus:border-skyBlue focus:border-2 focus:outline-none;
}

.form__input--success {
  @apply border-2 border-success rounded-lg p-2 w-full text-base bg-white hover:border-successHover focus:border-successHover focus:outline-none;
}

.form__input--error {
  @apply border-2 border-red rounded-lg p-2 w-full text-base bg-white hover:border-red focus:border-red focus:outline-none;
}

.select__input--rounded {
  @apply h-[42px] text-sm md:text-base block appearance-none w-full bg-white px-4 py-[4px] pr-8 rounded-lg shadow-sm leading-tight focus:outline-none focus:ring-1;
}

.select__input--error {
  @apply border-2 border-red hover:border-red focus:ring-red focus:border-red;
}

.select__input--valid {
  @apply border-2 border-success hover:border-successHover focus:ring-successHover focus:border-successHover;
}

.select__input--neutral {
  @apply border border-gray-300 hover:border-gray-400 focus:ring-skyBlue focus:border-skyBlue;
}

.select__icon {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-deepBlue;
}





  