

.confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    box-sizing: border-box;
  }
  
  .confirmation-icon {
    background-color: #4caf50;
    border-radius: 50%;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .confirmation-message {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
  
  
  
  
  
  /*Notificación */
  
  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .notification__content {
    width: 400px;
    height: 300px;
    transform: translateY(-33%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  
  .notification__icon-wrapper {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 50%;
  }
  
  .notification__icon {
    width: 100px;
    height: 100px;
  }
  
  .notification__text {
    color: #4a5568;
    font-size: 1.125rem;
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  
  